// libaries + styling
import { 
  Box,
  Button,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import "../../../css/Footer.css";

// components
import Image from "../../utils/Image";

// images
import logo from "../../../assets/images/first2umedia-logo.png";
import SocialMediaIcons from "./SocialMediaIcons";

const Footer = () => {
  return (
    <Box className="footer">
      <Box className="quick-links">
        <Button variant="text">
          <Link className="link" to="/featured-services">
            <Typography variant="white_h3">
              Featured Services
            </Typography>
          </Link>
        </Button>
        <Button variant="text">
          <Link className="link" to="/solutions">
            <Typography variant="white_h3">
              Solutions
            </Typography>
          </Link>
        </Button>
        <Button variant="text">
          <Link className="link" to="/about">
            <Typography variant="white_h3">
              About Us
            </Typography>
          </Link>
        </Button>
        <Button variant="text">
          <Link className="link" to="/privacy-policy">
            <Typography variant="white_h3">
              Privacy Policy
            </Typography>
          </Link>
        </Button>
        <Button variant="text">
          <Link className="link" to="/terms">
            <Typography variant="white_h3">
              Terms 
            </Typography>
          </Link>
        </Button>
      </Box>
      <Box 
        className="footer-bottom" 
        sx={{
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
            xl: "center"
          }
        }}
      >
        <Link 
          to="/" 
          color="inherit"
        >
          <Image 
            src={logo} 
            title={`logo`} 
            className={`logo-footer`}
          />
        </Link>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flexend",
            alignItems: "center",
            marginLeft: {
              xs: "auto",
              sm: "auto",
              md: "0",
              lg: "0",
              xl: "0"
            },
            margin: "2rem !important"
          }}
        >
          <SocialMediaIcons />
          <Typography 
            className="footer-bottom-copy" 
            variant="white_h3" 
          >
            &copy; 2022 First Digital LLC. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;