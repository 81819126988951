// libaries + styling
import {
  Box,
  Button,
  Link
} from "@mui/material";
import { FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa";

const SocialMediaIcons = () => {
  return (
    <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
      }}
    > 
      <Link
        underline="none"
        href="https://twitter.com/First2UMedia"
        sx={{
          color: "White",
          fontSize: 30,
          "&:hover": {
            color: "#2fb3b0",
            transition: "250ms ease-in-out"
          },
          padding: "1rem"
        }}
      >
        <FaTwitter />
      </Link>
      <Link
        underline="none"
        href="https://www.instagram.com/first2umedia/"
        sx={{
          color: "White",
          fontSize: 30,
          "&:hover": {
            color: "#2fb3b0",
            transition: "250ms ease-in-out"
          },
          padding: "1rem"
        }}
      >
        <FaInstagram />
      </Link>
      <Link
        underline="none"
        href=""
        sx={{
          color: "White",
          fontSize: 30,
          "&:hover": {
            color: "#2fb3b0",
            transition: "250ms ease-in-out"
          },
          padding: "1rem"
        }}
      >
        <FaFacebook />
      </Link>
    </Box>
  );
};

export default SocialMediaIcons;