// libraries + styling
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "../../../css/FeaturedServices.css";

// api
import api from "../../../api";

// components
import ContentWrapper from "../../utils/ContentWrapper";
import PayPerCall from "./PayPerCall";

// types
import { ContentList } from "../../../types/types";

const FeaturedServices = () => {
  const [content, setContent] = useState<ContentList>([]);

  const getFeaturedServices = async () => {
    const data: ContentList = await api.getFeaturedServices();
    setContent(data);
  };
  
  useEffect(() => {
    getFeaturedServices();
  }, []);

  return (
    <Box className="featured-services">
      {
        content && (
          content!.map((item) => (
            <React.Fragment key={item.id}>
              <ContentWrapper 
                reverse={item.reverse}
                src={item.src}
                title={item.title}
                h1={item.h1}
                subtitle={item.subtitle}
                button={item.button ?? undefined}
              />
              {
                (item.id === 1 ) && (
                  <PayPerCall />
                )
              }
            </React.Fragment>
          ))
        )
      }
    </Box>
  );
};

export default FeaturedServices;