// libraries + styling 
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "../../../css/Solutions.css";

// api
import api from "../../../api";

// types
import { LeadGenProblemList } from "../../../types/types";

// components
import ContentWrapper from "../../utils/ContentWrapper";
import ControlledAccordion from "../../utils/ControlledAccordion";

// types
import { ContentList } from "../../../types/types";

const Solutions = () => {
  const [content, setContent] = useState<ContentList>([]);
  const [listObjects, setListObjects] = useState<LeadGenProblemList>([]);

  const getSolutionsContent = async () => {
    const data: ContentList = await api.getSolutionsContent();
    setContent(data);
  };

  const getLeadGenProblems = async () => {
    const data: LeadGenProblemList = await api.getLeadGenProblems();
    setListObjects(data);
  };

  useEffect(() => {
    getSolutionsContent();
    getLeadGenProblems();
  }, []);

  return (
    <Box className="solutions">
      {
        content && (
          content!.map((item) => (
            <React.Fragment key={item.id}>
              <ContentWrapper 
                reverse={item.reverse}
                src={item.src}
                title={item.title}
                h1={item.h1}
                subtitle={item.subtitle}
                button={item.button ?? undefined}
              />
              {
                (item.id === 0 ) && (
                  <ControlledAccordion listObjects={listObjects} />
                )
              }
            </React.Fragment>
          ))
        )
      }
    </Box>
  );
};

export default Solutions;