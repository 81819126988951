// libraries + styling
import {
  Slide,
  useScrollTrigger,
} from "@mui/material";

import { NavFunctionalityProps } from "../../../../types/interfaces";

const HideOnScroll = (props: NavFunctionalityProps) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      { children }
    </Slide>
  );
};

export default HideOnScroll;