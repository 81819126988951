// libraries + styling 
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "../../../css/Home.css";

// api 
import api from "../../../api";

// components
import HeroContainer from "../../utils/HeroContainer";
import ContentWrapper from "../../utils/ContentWrapper"

// types
import { ContentList } from "../../../types/types";

// images
import placeholder from "../../../assets/images/about-expand-placeholder.png";

// video
const hero_world = require("../../../assets/videos/hero-video-world-fadeout.mp4");

const Home = (): JSX.Element => {
  const [content, setContent] = useState<ContentList>([]);

  const getHomeContent = async () => {
    const data: ContentList = await api.getHomeContent();
    setContent(data);
  };

  useEffect(() => {
    getHomeContent();
  }, []);

  return (
    <Box className="home">
      <HeroContainer 
        src={hero_world}
        placeholder={placeholder}
        placeholder_alt={`hero image`}
        className={`hero-video`}
        h1={`We take care of customer acquisition so you generate more sales`} // Learn how to increase your brand's visibility with high-quality leads
        subtitle1={[
          `Our partnerships with top brands can help to develop new streams of revenue within your existing business model.`
        ]}
        buttons={[
          {
            text: `Watch a Video`,
            componentId: `first2umedia-video`
          },
          {
            href: `/featured-services`,
            text: `Find Out More`
          }
        ]}
      />
      {
        content && (
          content!.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <ContentWrapper 
                  reverse={item.reverse}
                  componentId={item.componentId}
                  playOnClick={item.playOnClick ?? undefined}
                  src={item.src}
                  placeholder={item.placeholder}
                  title={item.title}
                  h1={item.h1}
                  h2={item.h2}
                  subtitle={item.subtitle}
                  button={item.button ?? undefined}
                />
              </React.Fragment>
            );
          })
        )
      }
    </Box>  
  );
}

export default Home;

/*
<FadeInSection>
  <Image src={Hero_1} title={`Hero image`} className={`hero`}/>
</FadeInSection>

<FadeContainer delay={1500}>
  <Image src={city_sunset} title={`Hero image`} className={`hero-image`} />
</FadeContainer>
*/ 