import { createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    hero_h1: true;
    hero_h2: true;
    hero_subtitle1: true;
    button_h3: true;
    white_h1: true;
    white_h2: true;
    white_h3: true;
    white_subtitle1: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  hero_1: React.CSSProperties;
  hero_2: React.CSSProperties;
  hero_subtitle1: React.CSSProperties;
  button_h3: React.CSSProperties;
  white_h1: React.CSSProperties;
  white_h2: React.CSSProperties;
  white_h3: React.CSSProperties;
  white_subtitle1: React.CSSProperties;
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // small phones
      sm: 300, // phones
      md: 600, // tablets
      lg: 900, // desktop
      xl: 1200 // larger screens
    }
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "button_h3" },
          style: {
            width: "100%",
            color: "white",
            fontSize: 14,
          }
        },
        {
          props: { variant: "hero_h1" },
          style: {
            width: "100%",
            maxwidth: "700px",
            color: "white",
            fontSize: 35,
            fontWeight: "600",
            textShadow: "rgb(76 76 76) 2px 2px",
            textAlign: "right",
            padding: "0rem 0rem 4rem 0.3rem"
          }
        },
        {
          props: { variant: "hero_h2" },
          style: {
            width: "100%",
            maxwidth: "700px",
            color: "white",
            fontSize: 18,
            fontWeight: "600",
            textShadow: "rgb(76 76 76) 1px 1px",
            textAlign: "right",
            padding: "0rem 0rem 2rem 0.3rem"
          }
        },
        {
          props: { variant: "hero_subtitle1" },
          style: {
            width: "100%",
            maxwidth: "700px",
            color: "white",
            fontSize: 14,
            textShadow: "rgb(76 76 76) 1px 1px",
            textAlign: "right",
            padding: "1rem 0rem 1rem 0.3rem"
          }
        },
        {
          props: { variant: "white_h1" },
          style: {
            fontSize: 35,
            fontWeight: 600,
            color: "white",
            textAlign: "left",
            padding: "1rem 1rem 2rem 1rem"
          }
        },
        {
          props: { variant: "white_h2" },
          style: {
            fontSize: 18,
            fontWeight: 600,
            color: "white",
            textAlign: "left",
            padding: "0.5rem !important"
          }
        },
        {
          props: { variant: "white_h3" },
          style: {
            fontSize: 14,
            fontWeight: 600,
            color: "white",
            textTransform: "none"
          }
        },
        {
          props: { variant: "white_subtitle1" },
          style: {
            fontSize: 14,
            color: "white",
            textAlign: "left",
            padding: "0.5rem 1rem 0.5rem 1rem"
          }
        }
      ]
    }
  },
  typography: {
    h1: {
      fontSize: 35,
      fontWeight: 600,
      color: "black",
      textAlign: "left",
      padding: "1rem 1rem 2rem 1rem"
    },
    h2: {
      fontSize: 18,
      fontWeight: 600,
      color: "black",
      textAlign: "left",
      padding: "0.5rem !important"
    },
    h3: {
      fontSize: 14,
      color: "black",
    },
    subtitle1: {
      fontSize: 14,
      color: "black",
      textAlign: "left",
      padding: "0.5rem 1rem 0.5rem 1rem"
    }
  }
});

export default theme;