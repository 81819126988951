// libraries + styling
import {
  AppBar,
  Box,
  Fab,
  Toolbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import "../../../css/Nav.css";

// components
import HamburgerIcon from "./HamburgerIcon";
import HamburgerContent from "./HamburgerContent";
import HideOnScroll from "./HideOnScroll";
import Image from "../../utils/Image";
import InlineNavigation from "./InlineNavigation";
import Scroll from "../../utils/Scroll";

// images
import logo from "../../../assets/images/first2umedia-logo.png";

// types
import { NavProps } from "../../../types/interfaces";

const Nav = ({ isOpen, setOpen }: NavProps): JSX.Element => {
  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed" className="nav">
          <Toolbar variant="dense" className="toolbar">
            <Link 
              to="/" 
              color="inherit"
            >
              <Image 
                src={logo} 
                title={`logo`} 
                className={`logo`}
                skeletonVariant={`circular`}
                skeletonClassName={`skeleton-logo`}
              />
            </Link>
            <Box className="navigation">
              <HamburgerIcon isOpen={isOpen} setOpen={setOpen}/>
              <InlineNavigation />
            </Box>
          </Toolbar>
          <HamburgerContent isOpen={isOpen} setOpen={setOpen} />
        </AppBar>
      </HideOnScroll>
      <Scroll componentId="back-to-top-anchor">
        <Fab sx={{ backgroundColor: "#2fb4b0" }} size="small" aria-label="scroll back to top">
          <FaArrowUp style={{ color: "white" }} />
        </Fab>
      </Scroll>
    </>
  );
};

export default Nav;