// libraries 
import React from "react";
import { 
  Box,
  Button, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemText, 
  Typography 
} from "@mui/material";
import { Link } from 'react-router-dom';

// types
import { NavProps } from "../../../../types/interfaces";

const HamburgerContent = ({ isOpen, setOpen }: NavProps) => {
  const closeNav = () => {
    if(setOpen) {
      setOpen(!isOpen);
    }
  };

  return (
    <Box className={`hamburger-content-wrapper ${isOpen ? "is-visible" : ""}`}>
      <List className={`hamburger-content`}>
        <ListItem>
          <ListItemButton>
            <ListItemText 
              onClick={closeNav}
              sx={{ textAlign: "center" }}
              primary={
                <Link 
                  className="link"
                  to="/featured-services"
                  color="inherit"
                >
                  <Typography variant="white_h3"> Featured Services </Typography>
                </Link>
              } 
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemText 
              onClick={closeNav}
              sx={{ textAlign: "center" }}
              primary={
                <Link 
                  className="link"
                  to="/solutions"
                  color="inherit"
                >
                  <Typography variant="white_h3"> Solutions </Typography>
                </Link>
              }  
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemText 
              onClick={closeNav}
              sx={{ textAlign: "center" }}
              primary={
                <Link
                  className="link"
                  to="/about"
                  color="inherit"
                >
                  <Typography variant="white_h3"> About Us </Typography>
                </Link>
              }  
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ justifyContent: "center" }}>
          <Button 
            onClick={closeNav} 
            className="styled-button" 
            variant="contained" 
            sx={{
              padding: "8px 16px 8px 16px"
            }}
          >
            <Link className="link" to="/request-estimate"> 
              <Typography variant="white_h3">
                Request an Estimate 
              </Typography>
            </Link> 
          </Button>
        </ListItem>
      </List>
    </Box>
  );
};

export default HamburgerContent;