// libraries + styling 
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { SiAirplayvideo } from "react-icons/si";

// components
import FadeContainer from "../FadeContainer";
import Image from "../Image";
import Scroll from "../Scroll";
import Video from "../Video";

// images
import logo from "../../../assets/images/first2umedia-logo.png";

// types
import { HeroContainerProps } from "../../../types/interfaces";

const HeroContainer = ({ 
  src, 
  placeholder, 
  placeholder_alt, 
  className,
  h1, 
  subtitle1,
  buttons
}: HeroContainerProps) => {
  return (
    <Box className="hero-container">
      <Box sx={{ background: "#011", width: "100%" }}>
        <Video 
          src={src} 
          placeholder={placeholder} 
          placeholder_alt={placeholder_alt} 
          className={className}
        />
      </Box>
      <Box className={`hero-content`} sx={{
          top: {
            xm: "5rem",
            sm: "5rem",
            md: "10rem",
            lg: "10rem",
            xl: "10rem",
          }
        }}
      >
        <Box className="hero-content-wrapper" sx={{ 
          
          width: {
            xm: "90%",
            sm: "90%",
            md: "85%",
            lg: "75%",
            xl: "75%",
          }
         }}
        >
          <FadeContainer delay={650} duration={1000}>
            <Image 
              src={logo} 
              title={`logo`} 
              className={`large-logo`}
              skeletonVariant={`circular`}
              skeletonClassName={`skeleton-logo`}
            />
          </FadeContainer>
          {
            h1 && (
              
              <FadeContainer delay={800} duration={1000}>
                <Box className="hero-inner-content">
                  <Typography variant="hero_h1" sx={{
                    width: {
                      xm: "100%",
                      sm: "100%",
                      md: "90%",
                      lg: "90%",
                      xl: "80%",
                    }
                  }}>
                     { h1 }
                  </Typography>
                </Box>
              </FadeContainer>
            )
          }
          {
            subtitle1?.map((text, idx) => (
              <FadeContainer key={idx} delay={950} duration={1000}>
                <Box className="hero-inner-content">
                  <Typography variant="hero_subtitle1"sx={{
                    width: {
                      xm: "100%",
                      sm: "100%",
                      md: "90%",
                      lg: "90%",
                      xl: "80%",
                    }
                  }}>
                    { text }
                  </Typography>
                </Box>
              </FadeContainer>
            ))
          }
          <Box 
            sx={{ 
              display: "flex",  
              flexDirection: {
                xm: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row"
              },
              justifyContent: "center",
              alignItems: "center"
            }}
            >
            {
              buttons?.map((button, idx) => (
                <Scroll key={idx} componentId={button.componentId ?? undefined}>
                  <FadeContainer delay={1100} duration={1000}>
                    <Box className="hero-inner-content">
                      <Button 
                        sx={{ 
                          textTransform: "unset",
                          margin: {
                            xm: "1rem 0 1rem 0",
                            sm: "1rem 0 1rem 0",
                            md: "1rem 0 1rem 0",
                            lg: "0 0 0 1rem",
                            xl: "0 0 0 1rem"
                          }
                        }} 
                        className="styled-button" 
                        variant="contained" 
                      >
                        {
                          button.componentId ? (
                            <Typography variant="white_h3" sx={{
                              width: "75%",
                              display: "inline-flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "space-around",
                              alignItems: "center"
                            }}>
                              { button.text } 
                              <SiAirplayvideo />
                            </Typography>
                          ) : (
                            <Link className="link" to={button.href ?? ""}> 
                              <Typography variant="white_h3">
                                { button.text }
                              </Typography>
                            </Link> 
                          )
                        }
                      </Button>
                    </Box>
                  </FadeContainer>
                </Scroll>
              ))
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroContainer;