import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const InlineNavigation = () => {
  return (
    <Box
      className="inline-navigation"
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "block",
          xl: "block"
        }
      }}
    > 
      <Button variant="text" sx={{ padding: "1rem", textTransform: "unset" }}> 
        <Link className="link" to="/featured-services">  
          <Typography variant="white_h3">
            Featured Services
          </Typography>
        </Link> 
      </Button>
      <Button variant="text" sx={{ padding: "1rem", textTransform: "unset" }}> 
        <Link className="link" to="/solutions">  
          <Typography variant="white_h3">
            Solutions
          </Typography>
        </Link> 
      </Button>
      <Button variant="text" sx={{ padding: "1rem", textTransform: "unset" }}> 
        <Link className="link" to="/about"> 
          <Typography variant="white_h3">
            About Us 
          </Typography>
        </Link> 
      </Button>
      <Button 
        sx={{ marginLeft: "1rem", textTransform: "unset" }} 
        className="styled-button" 
        variant="contained" 
      >
        <Link className="link" to="/request-estimate"> 
          <Typography variant="white_h3">
            Request an Estimate 
          </Typography>
        </Link> 
      </Button>
    </Box>
  );
};

export default InlineNavigation;

