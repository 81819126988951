// libraries + styling
import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

import FadeInSection from "../FadeInSection";

const ControlledAccordion = ({ listObjects }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  return (
    <Box sx={{ maxWidth: "1000px" }}>
      { listObjects.map((item, idx) => (
        <FadeInSection key={item.id}>
          <Accordion 
          expanded={expanded === `panel${idx}`} 
          onChange={handleChange(`panel${idx}`)}
          sx={{
            background: "transparent",
            boxShadow: "none",
            color: "white",
            borderBottom: "2px solid lightgrey",
            borderBottomLeftRadius: "0px !important",
            borderBottomRightRadius: "0px !important",
            borderWidth: "90% !important",
            padding: "1rem",
            width: "100%",
          }}
          >
            <AccordionSummary
              expandIcon={<FaAngleDown style={{ color: "black" }} />}
              aria-controls={`panel${idx}bh-content`}
              id={`panel${idx}bh-header`}
            >
              <Typography 
                sx={{ width: "95%", flexShrink: 0 }}
                variant="h2"
              >
                { item.headerText ? item.headerText : "" }
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "1rem" }}>
              { 
                item.subtext?.map((text, idx) => (
                  <Typography key={idx} variant="subtitle1">
                    { text }
                  </Typography>
                ))
              }
            </AccordionDetails>
          </Accordion>
        </FadeInSection>
      )) }
    </Box>
  );
};

export default ControlledAccordion;