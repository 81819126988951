// assets
import about_video_1_placeholder from "../assets/images/about-video-1-placeholder.png";
import about_video_2_placeholder from "../assets/images/about-video-2-placeholder.png";
import first2umedia_video_placeholder from "../assets/images/first2umedia_video_placeholder.png";
import business_meeting from "../assets/minimalistic/business-meeting.png";
import contact from "../assets/minimalistic/contact-me.svg";
import heavy_lifting from "../assets/minimalistic/heavy-lifting.png";
import location from "../assets/minimalistic/location.png";
import payment from "../assets/minimalistic/payment.png";
import save_money from "../assets/minimalistic/save-money.png";
import stock_market from "../assets/minimalistic/stock-market.png";
import teaching from "../assets/minimalistic/teaching.svg";

import about_video_1 from "../assets/videos/about-video-1-gif.gif";
import about_video_2 from "../assets/videos/about-video-2-gif.gif";

import { ContentList, defaultFormValues, ErrMsgs, FooterLinks, FormValidation, LeadGenProblemList, PayPerCallList } from "../types/types";

const first2umedia_video = require("../assets/videos/first2umedia_video_pitch.mp4");

//const about_video_1 = require("../assets/videos/about-video-1.mp4");
//const about_video_2 = require("../assets/videos/about-video-2.mp4");

export const aboutContent: ContentList = [
  {
    id: 0,
    reverse: false,
    playOnClick: false,
    src: about_video_1,
    placeholder: about_video_1_placeholder,
    title: `Targeted Advertising`,
    h1: `Targeted Advertising`,
    subtitle: [
      `First2UMedia is made up of a team of legal professionals, pioneers in the adverting industry, and TELCO professionals that combine with our technical experts to deliver an inbound call that produces qualified long term customers.`,
      `Our adverting is relevant and to the point because we strictly adhere to brand advertising guidelines, offers, and particular customer requirements.`
    ],
    about: true
  },
  {
    id: 1,
    reverse: true,
    playOnClick: false,
    src: about_video_2,
    placeholder: about_video_2_placeholder,
    title: `Led by Creative Experts`,
    h1: `Led by Creative Experts`,
    subtitle: [
      `First2uMedia is made up of a skilled team of legal, marketing, telecommunication, SEO, and web based data management and analytics professionals.`, 
      `Founding member Jonathan M Shiff a top rated litigation attorney who transitioned into the satellite services market at its inception.`,
      `He pioneered the industry's formidable growth and has held the distinction as the top independent satellite retailer in the country for over a decade.`,  
    ],
    about: true
  },
  {
    id: 2,
    reverse: false,
    src: location,
    title: `Inbound Customer Inquiries You Can Trust`,
    h1: `Inbound Customer Inquiries You Can Trust`,
    subtitle: [
      `As technology evolved, so did First2UMedia in diversifying its product offerings to include cable, security, and a full spectrum of insurance offerings.`,
      `Developing strategic partnerships directly with all major brands of home service and insurance verticals, First2UMedia generates over 500 daily customer inquires across its various brands which it provides to our various partners as qualified inbound customer inquiries.`,
      `We are continuously updating our products and services and would be happy to discuss how we can help your organization flourish.`,
    ],
    button: {
      href: "/request-estimate",
      text: "Request an Estimate"
    }
  },
];

export const defaultValues: defaultFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  businessUrl: "",
  targetCampaign: "",
  campaignGoal: "",
};

export const errMsgs: ErrMsgs = {
  required: {
    firstName: "A first name is required",
    lastName: "A last name is required",
    email: "A email is required",
    businessUrl: "A business URL is required",
    targetCampaign: "An explanation of your target campaign is required",
    campaignGoal: "An explanation of your campaign goal is required"
  },
  invalidEmail: "Please enter a valid email",
  invalidUrl: "Please enter a valid business URL"
};

export const formValidation: FormValidation = {
  firstName: false,
  lastName: false,
  email: false,
  businessUrl: false,
  targetCampaign: false,
  campaignGoal: false,
};

export const featuredServices: ContentList = [
  {
    id: 0,
    reverse: true,
    src: business_meeting,
    title: `Partnerships with top brands`,
    h1: `Partnerships with top brands`,
    subtitle: [
      `First2UMedia generates over 500 daily customer inquires across its various brands 
    which it provides to our various partners as qualified inbound customer inquiries.`,
    `We are continuously updating our products and services and would be happy to discuss how we can help your organization flourish.`  
    ]
  },
  {
    id: 1,
    reverse: false,
    src: save_money,
    title: `Full telecom and tracking services`,
    h1: `Full telecom and tracking services`,
    subtitle: [
      `First2UMedia has relationships with all major TELCO, Cable, Video, Security and Insurance Carriers and offers tailored access and solutions to individuals and business owners across the Country.`,
      `We buy in bulk so we can sell at a discount and help build single or bundled service solution for customers. Same service but at least twenty percent less expensive.` 
    ]
  },
  {
    id: 2,
    reverse: false,
    src: stock_market,
    title: `Make an informed and intelligent decision at the point of sale`,
    h1: `Make an informed and intelligent decision at the point of sale`,
    subtitle: [
      `Not only do we provide the services needed, we listen and implement solutions for individuals and businesses.`,
      `We are cutting edge in all wireless technologies and can seamlessly integrate this know how into existing systems and infrastructure.`
    ],
    button: {
      href: "/solutions",
      text: "Explore Solutions"
    }
  }
];

export const footerLinks: FooterLinks = [];

export const homeContent: ContentList = [
  {
    id: 0,
    reverse: false,
    src: contact,
    title: `Contact`,
    h1: `We only send interested customers to your call centers`,
    subtitle: [ 
    `With our lead generation program, we guarantee that only customers who want to buy your services will contact you, so they are most likely to convert into sales.`,
    `This way, you can maximize the total number of leads with a small and predictable budget.`
    ]
  },
  {
    id: 1,
    reverse: true,
    componentId: `first2umedia-video`,
    playOnClick: true,
    src: first2umedia_video,
    placeholder: first2umedia_video_placeholder,
    title: `First2UMedia Video`,
    h1: `Learn more about our performance marketing solutions`,
  },
  {
    id: 2,
    reverse: false,
    src: teaching,
    title: `Plan`,
    h1: `Why is a lead generation campaign necessary?`,
    subtitle: [
      `You'll be able to focus on the core components of your business.  
    We'll do the heavy lifting for you, and in return you'll receive high quality leads; if they don't convert, then you don't pay.`,
    `Simply fill out a few questions and your campaign is ready to run!`
    ],
    button: {
      href: "/request-estimate",
      text: "Request an Estimate"
    }
  }
];

export const leadGenProblems: LeadGenProblemList = [
  { id: 1, 
    headerText: "Problem 1: I need to generate a high volume of leads.", 
    subtext: [`If you’re just starting out, a lead generation program can result in increased brand awareness, new relationships, higher quality leads, and more sales.`, 
      `If you’re looking to optimize an existing program, you may want to reevaluate your audience profiles, buyer journey, channels, and tactics. Keep your goals, customer concerns, and challenges in mind, deliver content that solves their pain points, and keep nurturing those relationships—soon you’ll have a funnel filled with qualified leads.`
    ]
  },
  {
    id: 2,
    headerText: "Problem 2: My sales team says that I’m not delivering high-quality leads.",
    subtext: [`There are several reasons why your sales team is struggling to convert leads into customers.`, 
    `First, sales and marketing should be in agreement about what constitutes a qualified lead, and when that lead should be handed off, keeping in mind that 96% of people visiting your website aren’t ready to buy yet.`,
    `If sales contacts them too soon, they may feel put off—buyers today do not want to feel sold to. 
    Lead quality is important, but it’s a major challenge for marketers, so it may take some time to start bringing in qualified leads.`
    ]
  },
  {
    id: 3,
    headerText: "Problem 3: I’m bringing in leads, but don’t have a strategy around what to do next.",
    subtext: [`Generating leads is just one part of lead generation.`, 
    `Once you bring these leads in, you must utilize lead scoring and nurturing to qualify said leads before sales can do their job. 
    According to Forrester, buyers might be anywhere from 75 to 90% of the way through their buying journey before they contact the vendor, after they’ve completed their own research.`,
    `Keep this in mind when determining what point in the funnel sales should step in.`
    ]
  },
  {
    id: 4,
    headerText: "Problem 4: I need to be able to demonstrate the return on investment (ROI) of my marketing team.",
    subtext: [`To show the impact of your marketing team, you’ll need to plan out a strategy and determine what to measure, when to measure, and how to measure.`,
    `Choose metrics that show how marketing is increasing effectiveness across the board, generating qualified leads, amplifying sales pipeline velocity, and improving sales and marketing alignment through leveraging lead generation software.`
    ]
  },
  {
    id: 5,
    headerText: "Problem 5: My lead generation program isn’t working anymore.",
    subtext: [`If your lead generation strategy has yet to catch up to the age of the self-directed buyer, it’s time to reevaluate.`, 
    `Using modern lead generation software can help leads find you by increasing brand visibility and capturing interest with informational content buyers can use during their own research before they’re ready to purchase.`
    ]
  }
];

export const payPerCalls: PayPerCallList = [
  { id: 1, 
    headerText: "What is Pay-Per-Call?", 
    subtext: [
      `Pay per Call is a type of performance marketing where an advertiser pays publishers for quality calls generated on the advertiser's behalf.`, 
      `Put simply, pay-per-call tracks calls the same way performance networks tracks clicks.`
    ]
  },
  {
    id: 2,
    headerText: "What are the benefits for advertisers?",
    subtext: [
      `Advertisers who choose to publish pay-per-call campaigns are able to expand their distribution and inbound call volume across multiple channels with minimum added work on their part. They also have the benefit of complete visibility and control over call traffic.`,
      ``
    ]
  },
  {
    id: 3,
    headerText: "What verticals perform well for pay-per-call?",
    subtext: [
      `The best verticals for pay-per-call are considered purchase industries that focus on lead generation like healthcare, insurance, home services, travel, legal services, financial services, etc. It also works well with any high-consideration product or service where customers usually require a human touch at some point in the purchase journey. From a retail perspective, anything with an average order value north of $400 are also good candidates.`
    ]
  },
  {
    id: 4,
    headerText: "What are the benefits for publishers?",
    subtext: [
      `With pay-per-call, publishers can develop a new stream of revenue within their existing business model. It's not about clicks or calls, it's clicks and calls. Pay per call provides an opportunity to monetize online and phone traffic using the existing channels and promotional methods.`
    ]
  },
  {
    id: 5,
    headerText: "How are calls tracked to their source?",
    subtext: [`Calls are tracked in 2 ways:`, 
    `1. Unique tracking phone number — Each publisher or campaign is assigned a specific phone number. When a customer calls using that phone number, it is tied to the original source.`,
    `2. Dynamic tracking phone numbers — By placing a small snippet of code on a website or landing page, unique tracking numbers are automatically populated which capture key online touch points leading to a call - including publisher or referral source, campaign, and keyword.`
    ]
  },
  {
    id: 6,
    headerText: "Can calls be filtered?",
    subtext: [
      `Yes! Calls can be filtered based on conditions such as time and day of call, geographic location of the caller, phone type, and repeat vs. new caller.`
    ]
  },
  {
    id: 7,
    headerText: "Can calls be routed to multiple destination phone numbers or locations?",
    subtext: [
      `Yes.`
    ]
  },
  {
    id: 8,
    headerText: "When someone calls a business through a pay-per-call campaign, what is their experience?",
    subtext: [
      `For customers, making a call through a pay-per-call program is very similar to calling a business directly. Depending on the routing and filtering rules in place, calls will be connected to the advertiser as they normally would. If an IVR is in place, the customer will first go through the IVR prompts just as they would with any phone menu system. The customer won't be able to tell the difference.`
    ]
  }
];

export const solutions: ContentList = [
  {
    id: 0,
    reverse: true,
    src: payment,
    title: `Leave your customer acquisition to us, only pay for what coverts`,
    h1: `Leave your customer acquisition to us, only pay for what coverts`,
    subtitle: [
      `Simple batch and blast advertising does not appeal to the self-directed buyer, 
    which is why having a solid lead generation program in place can help you navigate the new complexity surrounding lead generation.`,
    `Below are a few problems lead generation can help solve.` 
    ]
  },
  {
    id: 1,
    reverse: false,
    src: heavy_lifting,
    title: `Let us do the heavy lifting of delivering quality customers to your call center`,
    h1: `Let us do the heavy lifting of delivering quality customers to your call center`,
    subtitle: [
      `All of this sounds pretty complicated and will only distract you from your core business. If you don't close the deal, you don't pay!`,
      `That's how confident we are in our ability to deliver quality calls that convert to sales.`
    ],
    button: {
      href: "/request-estimate",
      text: "Request an Estimate"
    }
  },
];



