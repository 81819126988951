import { Box } from "@mui/material";
import { Fade as Hamburger } from "hamburger-react";

// types
import { NavProps } from "../../../../types/interfaces";

const HamburgerIcon = ({ isOpen, setOpen }: NavProps) => {
  return (
    <Box 
      className="hamburger"
      sx={{
        display: {
          xs: "block",
          sm: "block",
          md: "block",
          lg: "none",
          xl: "none"
        }
      }}
    >
      <Hamburger toggled={isOpen} toggle={setOpen} />
    </Box>
  );
};

export default HamburgerIcon;