// libraries + styling 
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "../../../css/About.css";

// api
import api from "../../../api";

// components
import ContentWrapper from "../../utils/ContentWrapper";
import HeroContainer from "../../utils/HeroContainer";

// images
import placeholder from "../../../assets/images/digital-marketing-hero-placeholder.png"

// types
import { ContentList } from "../../../types/types";

// video
const dm_hero = require("../../../assets/videos/digital-marketing-hero.mp4");

const About = () => {
  const [content, setContent] = useState<ContentList>([]);

  const getAboutContent = async () => {
    const data: ContentList = await api.getAboutContent();
    setContent(data);
  };

  useEffect(() => {
    getAboutContent();
  }, []);

  return (
    <Box className="about">
      <HeroContainer 
        src={dm_hero}
        placeholder={placeholder}
        placeholder_alt={`about hero image`}
        className={`hero-video`}
        h1={`About Us`}
        subtitle1={[
          `First2UMedia and its family of companies have been leaders in providing high quality leads to over 20 major brands in the insurance, security, cable, satellite and internet industries.`, 
          `For over 20 years, we've enjoyed an unparalleled rate of success with the highest conversion rates in the industry.`,
        ]}
      />
      {
        content && (
          content!.map((item) => (
            <React.Fragment key={item.id}>
              <ContentWrapper 
                reverse={item.reverse}
                src={item.src}
                placeholder={item.placeholder}
                title={item.title}
                h1={item.h1}
                subtitle={item.subtitle}
                about={item.about ?? undefined}
                button={item.button ?? undefined}
              />
            </React.Fragment>
          ))
        )
      }
    </Box>
  );
};

export default About;