import { ContentList, defaultFormValues, PayPerCallList, LeadGenProblemList } from "../types/types";
import { aboutContent, defaultValues, featuredServices, homeContent, payPerCalls, leadGenProblems, solutions } from "./data";

export default class api {
  static async getAboutContent(): Promise<ContentList> {
    return (aboutContent) ? Promise.resolve(aboutContent) : Promise.reject(new Error(`Error when fetching aboutContent`));
  }

  static async getDefaultValues(): Promise<defaultFormValues> {
    return (defaultValues) ? Promise.resolve(defaultValues) : Promise.reject(new Error(`Error when fetching defaultValues`));
  }

  static async getFeaturedServices(): Promise<ContentList> {
    return (featuredServices) ? Promise.resolve(featuredServices) : Promise.reject(new Error(`Error when fetching featuredServices`));
  }

  static async getHomeContent(): Promise<ContentList> {
    return (homeContent) ? Promise.resolve(homeContent) : Promise.reject(new Error(`Error when fetching homeContent`));
  }

  static async getLeadGenProblems(): Promise<LeadGenProblemList> {
    return (leadGenProblems) ? Promise.resolve(leadGenProblems) : Promise.reject(new Error(`Error when fetching leadGenProblems`));
  }

  static async getPayPerCall(): Promise<PayPerCallList> {
    return (payPerCalls) ? Promise.resolve(payPerCalls) : Promise.reject(new Error(`Error when fetching payPerCalls`));
  }

  static async getSolutionsContent(): Promise<ContentList> {
    return (solutions) ? Promise.resolve(solutions) : Promise.reject(new Error(`Error when fetching solutions`));
  }
}