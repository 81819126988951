// libraries + styling 
import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";

interface ImageProps {
  src: string;
  title: string;
  className?: string;
  skeletonVariant?: string;
  skeletonClassName?: string;
}

const Image = ({ src, title, className, skeletonVariant, skeletonClassName }: ImageProps): JSX.Element => {
  const [isLoaded, setLoaded] = useState(false);

  const handleIsLoaded = () => {
    setLoaded(!isLoaded);
  };  

  return (
    <Box className="image-wrapper">
      {
        !isLoaded ? 
          <Skeleton variant="rectangular" className={skeletonClassName} /> :  
          <></>
      }
      <img onLoad={handleIsLoaded} className={className} src={src} alt={title} />
    </Box>
  );
}

export default Image;

