// libraries + styling 
import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";

const FadeInSection = (props) => {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    const cur = domRef.current;

    if(cur)
      observer.observe(cur);
    
    return () => { if(cur) observer.unobserve(cur) };
  }, []);

  return (
    <Box 
      className={`
        fade-in-section 
        ${isVisible ? "is-visible" : ""} 
      `}
      ref={domRef}
    >
      { props.children }
    </Box>
  );
};

export default FadeInSection;