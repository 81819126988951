// libraries + styling 
import React, { useRef, useState } from "react";
import { 
  AlertColor,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import "../../../css/RequestEstimate.css";

// api
import { formValidation } from "../../../api/data";

// components
import CustomizedSnackbar from "../../utils/CustomizedSnackbar";
import FadeContainer from "../../utils/FadeContainer";
import Image from "../../utils/Image";

// helpers
import { executeValidation } from "../../utils/Form";

// images 
import logo from "../../../assets/images/first2umedia-logo.png";

// types
import { defaultFormValues } from "../../../types/types";

const RequestEstimate = () => {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if(reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formRef = useRef<HTMLFormElement>(null);
  const {
    control,
    formState,
    handleSubmit,
    setError,
  } = useForm<defaultFormValues>();
  const { errors, isSubmitting } = formState;

  const getEmailCredentials = async (data: defaultFormValues) => {
    try {
      let result = await axios.get("https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-f7b69b5d-b798-4524-a38e-bc56427236ba/default/emailjs-key");

      emailjs.send('first2umedia_service', 'first2umedia_template', data ?? {}, result.data.emailjs_pkey)
      .then(() => {
        console.log("Submission success");
        setMessage(`Success! We have recorded your response`);
        setSeverity(`success`);
        setOpen(true);
      }, (error) => {
        setMessage(`We are unable to process your response, try again`);
        setSeverity(`error`);
        setOpen(true);
        
        console.log('Submission failed', error);
      });
    }
    catch(err) {
      throw new Error(`${err}`);
    }
  };

  const onSubmit = async ({ firstName, lastName, email, businessUrl, targetCampaign, campaignGoal }: defaultFormValues) => {
    const data: defaultFormValues = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      businessUrl: businessUrl,
      targetCampaign: targetCampaign,
      campaignGoal: campaignGoal
    };

    let complete = executeValidation(data, formValidation, setError);

    if(complete) {
      getEmailCredentials(data); 
    }
    else {
      setMessage(`Form has invalid data, try again`);
      setSeverity(`error`);
      setOpen(true);
    }
  };

  return (
    <Box className="request-estimate-page">
      <Box 
        className="request-estimate-card" 
        sx={{ 
          maxWidth: {
            xs: "95%",
            sm: "95%",
            md: "95%",
            lg: "60%",
            xl: "50%"
          } 
        }}
      > 
        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FadeContainer delay={650} duration={1000}>
            <Image 
              src={logo} 
              title={`logo`} 
              className={`reqEst-logo`}
              skeletonVariant={`circular`}
              skeletonClassName={`skeleton-logo`}
            />
          </FadeContainer>
          <FadeContainer delay={800} duration={1000}>
            <Typography variant="white_h1" sx={{ display: "flex", textAlign: "center !important" }}>
              Request a Free Estimate Today
            </Typography>
          </FadeContainer>
        </Box>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="form">
          <Box sx={{ width: "100%", padding: "1rem" }}>
            <Controller 
              control={control}
              name="firstName"
              render={({
                field: { onChange, onBlur, ref, value },
              }) => (
                <FadeContainer delay={950} duration={1000}>
                  <TextField 
                    id="first-name-input"
                    name="firstName"
                    label="First Name *"
                    type="text"
                    variant="standard"
                    defaultValue={value ?? ""}
                    helperText={errors.firstName?.message ?? ""}
                    error={(errors.firstName ? true : false)}
                    inputRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isSubmitting}
                    sx={{ width: "100%" }}
                  />
                </FadeContainer>
              )}
            />
          </Box>
          <Box sx={{ width: "100%", padding: "1rem" }}>
            <Controller 
              control={control}
              name="lastName"
              render={({
                field: { onChange, onBlur, ref, value },
              }) => (
                <FadeContainer delay={1100} duration={1000}>
                  <TextField 
                    id="last-name-input"
                    name="lastName"
                    label="Last Name *"
                    type="text"
                    variant="standard"
                    defaultValue={value ?? ""}
                    helperText={errors.lastName?.message ?? ""}
                    error={(errors.lastName ? true : false)}
                    inputRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isSubmitting}
                    sx={{ width: "100%" }}
                  />
                </FadeContainer>
              )}
            />
          </Box>
          <Box sx={{ width: "100%", padding: "1rem" }}>
            <Controller 
              control={control}
              name="email"
              render={({
                field: { onChange, onBlur, ref, value },
              }) => (
                <FadeContainer delay={1250} duration={1000}>
                  <TextField 
                    id="email-input"
                    name="email"
                    label="Email *"
                    type="text"
                    variant="standard"
                    defaultValue={value ?? ""}
                    helperText={errors.email?.message ?? ""}
                    error={(errors.email ? true : false)}
                    inputRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isSubmitting}
                    sx={{ width: "100%" }}
                  />
                </FadeContainer>
              )}
            />
          </Box>
          <Box sx={{ width: "100%", padding: "1rem" }}>
            <Controller 
              control={control}
              name="businessUrl"
              render={({
                field: { onChange, onBlur, ref, value },
              }) => (
                <FadeContainer delay={1400} duration={1000}>
                  <TextField 
                    id="businsess-url-input"
                    name="businessUrl"
                    label="Business URL *"
                    type="text"
                    variant="standard"
                    defaultValue={value ?? ""}
                    helperText={errors.businessUrl?.message ?? ""}
                    error={(errors.businessUrl ? true : false)}
                    inputRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isSubmitting}
                    sx={{ width: "100%" }}
                  />  
                </FadeContainer>
              )}
            />
          </Box>
          <Box sx={{ width: "100%", padding: "1rem" }}>
            <Controller 
              control={control}
              name="targetCampaign"
              render={({
                field: { onChange, onBlur, ref, value },
              }) => (
                <FadeContainer delay={1550} duration={1000}>
                  <TextField 
                    id="target-market-campaign"
                    name="targetCampaign"
                    label="What is your target market for this campaign? *"
                    type="text"
                    variant="standard"
                    multiline
                    maxRows={5}
                    defaultValue={value ?? ""}
                    helperText={errors.targetCampaign?.message ?? ""}
                    error={(errors.targetCampaign ? true : false)}
                    inputRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isSubmitting}
                    sx={{ width: "100%" }}
                  />
                </FadeContainer>
              )}
            />
          </Box>
          <Box sx={{ width: "100%", padding: "1rem" }}>
            <Controller 
              control={control}
              name="campaignGoal"
              render={({
                field: { onChange, onBlur, ref, value },
              }) => (
                <FadeContainer delay={1700} duration={1000}>
                  <TextField 
                    id="campaign-goal"
                    name="campaignGoal"
                    label="What is the goal of your campaign? *"
                    type="text"
                    variant="standard"
                    multiline
                    maxRows={5}
                    defaultValue={value ?? ""}
                    helperText={errors.campaignGoal?.message ?? ""}
                    error={(errors.campaignGoal ? true : false)}
                    inputRef={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={isSubmitting}
                    sx={{ width: "100%" }}
                  />  
                </FadeContainer>
              )}
            />
          </Box>
          <Box sx={{ display: "inline-flex", justifyContent: "center", width: "100%" }}>
            <FadeContainer delay={1850} duration={1000}>
              <Button type="submit" className="submit-button" disabled={isSubmitting}>
                <Typography variant="white_h3">
                  Submit
                </Typography>
              </Button>
            </FadeContainer>
          </Box>
        </form>
      </Box>
      <CustomizedSnackbar 
        handleClose={handleClose}
        message={message}
        open={open}
        severity={severity}
      />
    </Box>
  );
};

export default RequestEstimate;