// libraries + styling 
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// components
import FadeInSection from "../FadeInSection";
import Image from "../Image";
import Video from "../Video";

// functions
import { isImage } from "./functions";

// types
import { ContentWrapperProps } from "../../../types/interfaces";

const ContentWrapper = ({ reverse, componentId, playOnClick, src, placeholder, title, h1, h2, subtitle, about, button }: ContentWrapperProps) => {
  return (
    <Box className={`content ${reverse ? "row-reverse" : "row"}`} id={`${componentId}`}>
      <Box
        sx={{ 
          width: {
            xs: about ? "90%" : "100%",
            sm: about ? "90%" : "100%",
            md: about ? "70%" : "70%",
            lg: about ? "40%" : "40%",
            xl: about ? "30%" : "40%"
          },
          maxWidth: "1000px"
        }}
      >
        <FadeInSection>
          {
            (isImage(src)) ? (
              <Image 
                className={about ? `about-image` : `image`}
                skeletonClassName={`skeleton`}
                skeletonVariant={`rectangular`}
                src={src} 
                title={title} 
              />
            ) : (
              <Video 
                src={src} 
                playOnClick={playOnClick}
                placeholder={placeholder ?? ""} 
                placeholder_alt={title} 
                className={`video`}
              />
            ) 
          }
        </FadeInSection>
      </Box>
      <Box 
        className="content-desc"
        sx={{
          margin: "0rem 0rem 2rem 0rem",
          padding: "1rem",
        }}
      >
        {
          h1 && (
            <FadeInSection>
              <Typography variant={reverse ? "white_h1" : "h1"}> 
                { h1 }
              </Typography>
            </FadeInSection>
          )
        }
        {
          h2 && (
            <FadeInSection>
              <Typography variant={reverse ? "white_h2" : "h2"}> 
                { h2 }
              </Typography>
            </FadeInSection>
          )
        }
        {
          subtitle?.map((text, idx) => (
            <FadeInSection key={idx}>
              <Typography variant={reverse ? "white_subtitle1" : "subtitle1"}>
                { text }
              </Typography>
            </FadeInSection>
          ))
        }
        {
          button && (
            <FadeInSection>
              <Button 
              sx={{ margin: "1rem", textTransform: "unset" }} 
              className="styled-button" 
              variant="contained" 
              >
                <Link className="link" to={button.href ?? ""}> 
                  <Typography variant="white_h3">
                    { button.text ?? ""}
                  </Typography>
                </Link> 
              </Button> 
            </FadeInSection>
          )
        }
      </Box>
    </Box>
  );
};

export default ContentWrapper;