// functions
import isEmpty from "./isEmpty";
import validEmail from "./validEmail";
import validUrl from "./validUrl";

// api
import { errMsgs } from "../../../api/data";

// libraries
import { UseFormSetError } from "react-hook-form";

// types
import { defaultFormValues, FormValidation } from "../../../types/types";

const executeValidation = (data: defaultFormValues, formValidation: FormValidation, setError: UseFormSetError<defaultFormValues>): boolean => {
  for(const prop in data) {
    if(isEmpty(data[prop])) {
      switch(prop) {
        case "firstName":
          setError("firstName", { message: errMsgs.required[prop] });
          break;
        case "lastName":
          setError("lastName", { message: errMsgs.required[prop] });
          break;
        case "email":
          setError("email", { message: errMsgs.required[prop] });
          break;
        case "businessUrl":
          setError("businessUrl", { message: errMsgs.required[prop] });
          break;
        case "targetCampaign":
          setError("targetCampaign", { message: errMsgs.required[prop] });
          break;
        case "campaignGoal":
          setError("campaignGoal", { message: errMsgs.required[prop] });
          break;
        default:
          throw new Error(`${prop} does not exist`);
      }
    }
    else {
      formValidation[prop] = true;
    }
  }

  if(!validEmail(data.email)) {
    setError("email", { message: errMsgs.invalidEmail });
    formValidation.email = false;
  }
  else {
    formValidation.email = true;
  }

  if(!validUrl(data.businessUrl)) {
    setError("businessUrl", { message: errMsgs.invalidUrl });
    formValidation.businessUrl = false
  }
  else {
    formValidation.businessUrl = true;
  }

  for(const key in formValidation) {
    if(formValidation[key] === false) return false;
  }

  return true;
};

export default executeValidation;