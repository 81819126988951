// libraries + styling
import {
  Box,
  useScrollTrigger,
  Zoom
} from "@mui/material";

// types
import { NavFunctionalityProps } from "../../../types/interfaces";

const Scroll = (props: NavFunctionalityProps) => {
  const { children, window, componentId } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector(`#${componentId}`);

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (componentId === "back-to-top-anchor") ? (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 50 }}
      >
        {children}
      </Box>
    </Zoom>
  ) : (
    <Box onClick={handleClick} role="presentation">
      {children}
    </Box>
  );
};

export default Scroll;