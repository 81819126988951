// libaries + styling
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ThemeProvider, Box } from "@mui/material";
import theme from "../../css/theme";
import '../../css/App.css';


// components
import About from './About';
import FeaturedServices from './FeaturedServices';
import Footer from './Footer';
import Home from "./Home";
import Nav from "./Nav";
import PrivacyPolicy from './PrivacyPolicy';
import RequestEstimate from './RequestEstimate';
import ScrollToTop from '../utils/ScrollToTop';
import Solutions from './Solutions';
import Terms from "./Terms";


const App = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    // if hamburger content is open, close it if scrolling occurs
    const onScroll = () => {
      if(isOpen) setOpen(!isOpen);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isOpen, setOpen]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Box className="app">
            <Nav isOpen={isOpen} setOpen={setOpen} />
            <span id="back-to-top-anchor"></span>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/featured-services" element={<FeaturedServices />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/about" element={<About />} />
              <Route path="/request-estimate" element={<RequestEstimate />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
            <Footer />
          </Box>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  );
}

export default App;
