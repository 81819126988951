// libraries
import React from "react";
import { 
  Snackbar,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

// types
import { CustomizedSnackbarProps } from "../../../types/interfaces";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref,) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbar = ({ handleClose, message, open, severity }: CustomizedSnackbarProps) => {

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        { message }
      </Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbar;