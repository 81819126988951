// libraries + styling
import React, { useState, createRef } from "react";
import { Box } from "@mui/material";

// types
import { VideoProps } from "../../../types/interfaces";

const Video = ({ src, playOnClick, placeholder, placeholder_alt, className }: VideoProps) => {
  const [isLoaded, setLoaded] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const videoRef = createRef<HTMLVideoElement>();

  const handleClick = () => {
    setClicked(!isClicked);
    
    videoRef.current!.play();  
  };
  
  const handleIsLoaded = () => {
    setLoaded(!isLoaded);
  };

  return (
    <Box sx={{ 
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    }}>
      {
        !isLoaded && (
          <img 
            src={placeholder}
            alt={placeholder_alt}
            className={(className === "hero-video") ? "hero-video-placeholder" : "video-placeholder"}
          />
        )
      }
      <video 
        ref={videoRef}
        onLoadedData={handleIsLoaded} 
        className={`${className}`} 
        controls={playOnClick ? true : false}
        style={{ opacity: isLoaded ? 1 : 0 }} 
        loop 
        autoPlay={!playOnClick ? true : false} 
        muted={!playOnClick ? true : false}
        playsInline={!playOnClick ? true : false}
        preload="metadata"
      >
        <source src={src} type="video/mp4" />
        <img className={`image`} src={placeholder} title={"Your browser does not support the <video> tag."} />
      </video>
      {
        playOnClick && (
          !isClicked && (
            <img 
              onClick={handleClick}
              src={placeholder} 
              title={placeholder_alt} 
              style={{ 
                width: "85%", 
                height: "100%", 
                position: "absolute", 
                cursor: "pointer",  
                borderRadius: "5px"
              }}
            />
          )
        )
      }
    </Box>
  );
};

export default Video;