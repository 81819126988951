// libraries + styling
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

// api
import api from "../../../../api";

// components
import ContentWrapper from "../../../utils/ContentWrapper";
import ControlledAccordion from "../../../utils/ControlledAccordion";

// images 
import help_eachother from "../../../../assets/minimalistic/helping-each-other.png";

// types
import { PayPerCallList } from "../../../../types/types";

const PayPerCall = () => {
  const [listObjects, setListObjects] = useState<PayPerCallList>([]);

  const getPayPerCall = async () => {
    const data: PayPerCallList = await api.getPayPerCall();
    setListObjects(data);
  };

  useEffect(() => {
    getPayPerCall();
  }, []);

  return (
    <Box className="pay-per-call">
      <ContentWrapper
        reverse={true} 
        src={help_eachother} 
        title={`Explore how we can help you`}
        h1={`Explore how we can help you`}
      />
      <ControlledAccordion listObjects={listObjects} />
    </Box>
  );
};

export default PayPerCall;